
import { nextTick, onMounted, inject, ref } from "vue";
import { DeviceHandlerService } from "@/services/DeviceHandlerService";
import DeviceSelection from "@/components/DeviceSelection.vue";
import { ConfigurationService } from "@/services/ConfigurationService";
import { ManagementApiService } from "@/services/ManagementApiService";

export default {
  name: "DevicePreview",
  emits: ["cancel", "ok"],
  components: { DeviceSelection },
  // eslint-disable-next-line
  setup(props: any, context: any): any {
    const deviceHandler = inject("DeviceHandlerService") as DeviceHandlerService;
    const configuration = inject("ConfigurationService") as ConfigurationService;
    const managementApi = inject("ManagementApiService") as ManagementApiService;
    let mediaStream: MediaStream;
    const username = ref("");
    const sessionName = ref("");
    const camOn = ref(true);
    const micOn = ref(true);
    const errortext = ref("");
    const isLoading = ref(false);
    const interpreterServiceActive = configuration.session.interpreterServiceActive;
    const spokenLanguage = ref(configuration.persistent.spokenLanguage);

    onMounted(
      async (): Promise<void> => {
        deviceHandler.camId.value = configuration.persistent.camera;
        deviceHandler.micId.value = configuration.persistent.microphone;
        deviceHandler.speakerId.value = configuration.persistent.speaker;
        username.value = configuration.persistent.userName;
        sessionName.value = configuration.session.sessionTitle;

        await deviceHandler.updateDevices();

        nextTick(() => {
          showVideo(deviceHandler.camId.value);
        });
      }
    );

    const showVideo = async (id: string): Promise<void> => {
      const videoElement = document.querySelector<HTMLVideoElement>("#localVideo");

      if (videoElement != null) {
        deviceHandler.camId.value = id;
        mediaStream = await deviceHandler.openCamera(id);
        videoElement.srcObject = mediaStream;
      }
    };

    const cancelSetup = (): void => {
      deviceHandler.closeCamera(mediaStream);
      context.emit("cancel");
    };

    const selectCam = (id: string): void => {
      const stream = mediaStream;
      showVideo(id);
      // console.log(stream.getVideoTracks()[0].getCapabilities());
      // console.log(stream.getVideoTracks()[0].getConstraints());
      // console.log(stream.getVideoTracks()[0].getSettings());
      deviceHandler.closeCamera(stream);
    };

    const selectMic = (id: string): void => {
      deviceHandler.micId.value = id;
    };

    const selectSpeaker = (id: string): void => {
      deviceHandler.speakerId.value = id;
    };

    const ok = async (): Promise<void> => {
      errortext.value = "";
      configuration.persistent.camera = deviceHandler.camId.value;
      configuration.persistent.microphone = deviceHandler.micId.value;
      configuration.persistent.speaker = deviceHandler.speakerId.value;
      configuration.persistent.userName = username.value;
      configuration.persistent.spokenLanguage = spokenLanguage.value;
      configuration.session.camOn = camOn.value;
      configuration.session.micOn = micOn.value;

      try {
        isLoading.value = true;
        const res = await managementApi.callLogin(configuration.session.token);
        configuration.session.userId = res.uid;
        configuration.session.token = res.token;
        configuration.session.room = res.room;
        configuration.session.codec = res.codec;
        configuration.session.tokenRenewInterval = res.tokenRenewInterval;
        configuration.session.keepAliveInterval = res.keepAliveInterval;
        isLoading.value = false;
        configuration.save();
        deviceHandler.closeCamera(mediaStream);
        context.emit("ok");
      } catch (e) {
        console.error(e);
        isLoading.value = false;
        errortext.value = e.message;
      }
    };

    return {
      deviceHandler,
      cancelSetup,
      selectCam,
      selectMic,
      selectSpeaker,
      username,
      sessionName,
      camOn,
      micOn,
      ok,
      errortext,
      isLoading,
      interpreterServiceActive,
      spokenLanguage,
    };
  },
};
