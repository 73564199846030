<template>
  <div class="error">
    Wir haben kein Zugriff auf ihre Video- oder Audiogeräte.<br />
    Bitte überprüfen sie ihre Sicherheitseinstellungen.
  </div>
</template>

<style scoped>
.error {
  color: var(--color-error);
  text-align: center;
}
</style>
