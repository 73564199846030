
import { DeviceInfo } from "@/services/DeviceHandlerService";

export default {
  name: "DeviceSelection",
  props: {
    devices: {
      type: Object as () => DeviceInfo[],
      required: true,
    },
    selectedId: {
      type: Object as () => string,
      required: true,
    },
  },
  emits: ["select"],
  setup(props: any, context: any): any {
    const select = (id: string): void => {
      context.emit("select", id);
    };

    return { select };
  },
};
