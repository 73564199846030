import axios, { AxiosRequestConfig } from "axios";
const ManagementApiBasePath = "https://babylonvideoservice.azurewebsites.net/api/";
const SessionInformationFunctionKey = "sIzuqUarQqfPh8Writrhg6kIgkRySLUjfbuPlgD/7jqW2GB0mGsfxw==";
const CallLoginFunctionKey = "XROarUEbD59WZ0oGBjNEAmURKKrVSdcEBxY9XEmR9s4dvYcgB4nX/w==";
const SendCommandFunctionKey = "SdyghHtZHfyw29NjT3yBWeILPjY8QsLG3kPKcE4Fi7ZNdByaStx4Vw==";
const RenewTokenFunctionKey = "TwnIDm05EcXtaoRC6O2DPyRsxwLv6sCcUzXU6CG0aHMaQ6beK/d6Vw==";
const GetApiTokenFunctionKey = "jcacoUwSKFuq1KnfBqRVaDZxTDj9aaDljyLQFwnFfnwGbxAl3CmMLw==";

export enum SessionInformationStatus {
  Unknown = 0,
  UnknownMeetingId = 1,
  PasswordRequired = 2,
  PasswordWrong = 3,
  InternalServerError = 4,
  OK = 5,
}

export interface SessionInformationRequest {
  sessionId: string;
  password: string;
}

export interface SessionInformationResponse {
  sessionName: string;
  token: string;
  status: SessionInformationStatus;
  interpreterActive: boolean;
  error: string;
}

export interface CallLoginRequest {
  token: string;
}

export interface CallLoginResponse {
  room: string;
  uid: string;
  token: string;
  codec: "h264" | "vp8";
  tokenRenewInterval: number;
  keepAliveInterval: number;
}

export interface RenewTokenRequest {
  token: string;
}

export interface RenewTokenResponse {
  token: string;
}

export interface GetApiTokenRequest {
  token: string;
}

export interface GetApiTokenResponse {
  token: string;
  audio: boolean;
  video: boolean;
}

export interface SendCommandRequest {
  token: string;
  command: Command;
}

export interface Command {
  sender?: string;
  command: "Hello" | "Bye" | "Ping" | "Iam" | "Hand";
  data?: string;
}

export interface CommandIam {
  name: string;
  rtcUid: string;
  videoMuted: boolean;
  audioMuted: boolean;
  handUp: boolean;
  spokenLanguage: string;
}

export interface CommandHand {
  up: boolean;
}

export class ManagementApiService {
  apiClient;

  constructor() {
    this.apiClient = axios.create({
      baseURL: ManagementApiBasePath,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  async getSessionInformation(id: string, password: string): Promise<SessionInformationResponse> {
    const request: SessionInformationRequest = { sessionId: id, password: password };
    const config: AxiosRequestConfig = {
      headers: { "x-functions-key": SessionInformationFunctionKey },
    };
    // await new Promise(resolve => setTimeout(resolve, 2500));

    return (
      await this.apiClient.post<SessionInformationResponse>("SessionInformation", request, config)
    ).data;
  }

  async callLogin(token: string): Promise<CallLoginResponse> {
    const request: CallLoginRequest = { token: token };
    const config: AxiosRequestConfig = {
      headers: { "x-functions-key": CallLoginFunctionKey },
    };
    // await new Promise(resolve => setTimeout(resolve, 2500));

    return (await this.apiClient.post<CallLoginResponse>("CallLogin", request, config)).data;
  }

  async renewToken(token: string): Promise<RenewTokenResponse> {
    const request: RenewTokenRequest = { token: token };
    const config: AxiosRequestConfig = {
      headers: { "x-functions-key": RenewTokenFunctionKey },
    };

    return (await this.apiClient.post<RenewTokenResponse>("RenewToken", request, config)).data;
  }

  async getApiToken(token: string): Promise<GetApiTokenResponse> {
    const request: GetApiTokenRequest = { token: token };
    const config: AxiosRequestConfig = {
      headers: { "x-functions-key": GetApiTokenFunctionKey },
    };

    return (await this.apiClient.post<GetApiTokenResponse>("GetApiToken", request, config)).data;
  }

  async sendCommand(token: string, command: Command): Promise<void> {
    const request: SendCommandRequest = { token: token, command: command };
    const config: AxiosRequestConfig = {
      headers: { "x-functions-key": SendCommandFunctionKey },
    };

    await this.apiClient.post<CallLoginResponse>("SendCommand", request, config);

    return;
  }
}
