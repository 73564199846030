<template>
  <DeviceAccessRequest v-if="mediaSupported === DeviceAccessState.Request" />
  <DeviceAccessDenied v-if="mediaSupported === DeviceAccessState.Denied" />
  <DevicePreview
    v-if="mediaSupported === DeviceAccessState.Granted"
    @cancel="cancelSetup"
    @ok="okSetup"
  />
</template>

<script lang="ts">
import { ref, onMounted, inject } from "vue";
import { DeviceHandlerService } from "@/services/DeviceHandlerService";
import DeviceAccessRequest from "@/components/DeviceAccessRequest.vue";
import DeviceAccessDenied from "@/components/DeviceAccessDenied.vue";
import DevicePreview from "@/components/DevicePreview.vue";

enum DeviceAccessState {
  Request = 0,
  Denied = 1,
  Granted = 2,
}

export default {
  name: "VideoSetup",
  components: { DeviceAccessRequest, DeviceAccessDenied, DevicePreview },
  emits: ["cancel", "ok"],
  setup(props: any, context: any): any {
    const deviceHandler = inject("DeviceHandlerService") as DeviceHandlerService;
    const mediaSupported = ref(DeviceAccessState.Request);

    onMounted(
      async (): Promise<void> => {
        mediaSupported.value = (await deviceHandler.isMediaSupported())
          ? DeviceAccessState.Granted
          : DeviceAccessState.Denied;
      }
    );

    const cancelSetup = (): void => {
      context.emit("cancel");
    };

    const okSetup = (): void => {
      context.emit("ok");
    };

    return { mediaSupported, cancelSetup, okSetup, DeviceAccessState };
  },
};
</script>
