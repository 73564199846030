
import {
  onMounted,
  onUnmounted,
  inject,
  toRefs,
  computed,
  nextTick,
  ref,
  reactive,
  watch,
} from "vue";
import { ConfigurationService } from "@/services/ConfigurationService";
import * as Twilio from "twilio-video";
import {
  ManagementApiService,
  Command,
  CommandIam,
  CommandHand,
} from "@/services/ManagementApiService";

interface Attendee {
  uid: string;
  name: string | null;
  initials: string | null;
  initialsSvg: string | null;
  lastSeen: number;
  speaking: boolean;
  audioMuted: boolean;
  videoMuted: boolean;
  handUp: boolean;
  spokenLanguage: string | null;
  track: Twilio.Track[];
}

interface LanguageChannel {
  name: string;
  iso: string;
  enabled: boolean;
}

export default {
  name: "Call",
  emits: ["exit"],
  // eslint-disable-next-line
  setup(props: any, context: any): any {
    const configuration = inject("ConfigurationService") as ConfigurationService;
    const managementApi = inject("ManagementApiService") as ManagementApiService;
    const audioOn = ref(true);
    const videoOn = ref(true);
    const handUp = ref(false);
    const interpreterServiceActive = ref(configuration.session.interpreterServiceActive);
    const attendees: Set<Attendee> = reactive(new Set());
    const speakingAttendees: Set<Attendee> = reactive(new Set());
    const channels: Set<LanguageChannel> = reactive(new Set());
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    let pingInterval: number;
    let tokenRenewInterval: number;
    let cleanupInterval: number;
    let room: Twilio.Room;
    let audioTrack: Twilio.LocalAudioTrack;
    let videoTrack: Twilio.LocalVideoTrack;

    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    const audioContext = AudioContext ? new AudioContext() : null;

    onMounted(
      async (): Promise<void> => {
        configuration.session.apiToken = (
          await managementApi.getApiToken(configuration.session.token)
        ).token;

        bootstrapTwilio();
      }
    );

    onUnmounted(
      async (): Promise<void> => {
        window.clearInterval(pingInterval);
        window.clearInterval(cleanupInterval);
        window.clearInterval(tokenRenewInterval);

        room.disconnect();

        // await commandSendBye();
        window.removeEventListener("beforeunload", windowClose);
        // await subscription.close();
        // await receiver.close();
        // await serviceBusClient.close();
      }
    );

    const bootstrapTwilio = async () => {
      audioTrack = await Twilio.createLocalAudioTrack({
        name: "microphone",
        deviceId: {
          exact: configuration.persistent.microphone,
        },
        autoGainControl: true,
        noiseSuppression: true,
        echoCancellation: true,
      });

      document.querySelector("#localvideo>div.container")?.appendChild(audioTrack.attach());

      videoTrack = await Twilio.createLocalVideoTrack({
        name: configuration.persistent.userName,
        deviceId: {
          exact: configuration.persistent.camera,
        },
        height: !isMobileDevice ? 720 : 480,
        frameRate: 24,
        width: !isMobileDevice ? 1280 : 640,
      });

      document.querySelector("#localvideo>div.container")?.appendChild(videoTrack.attach());
      console.log(`Joining room ${configuration.session.room} for ${configuration.session.userId}`);

      room = await Twilio.connect(configuration.session.apiToken, {
        name: configuration.session.room,
        enableDscp: true,
        bandwidthProfile: {
          video: {
            mode: "grid",
            maxSubscriptionBitrate: isMobileDevice ? 2500000 : 0,
          },
        },
        maxAudioBitrate: 16000, //For music remove this line
        preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
        tracks: [audioTrack, videoTrack],
        // dominantSpeaker: true,
      });

      audioOn.value = configuration.session.micOn;
      videoOn.value = configuration.session.camOn;

      pollAudioLevel(audioTrack, (level: number) => {
        console.log(level);
      });

      window.addEventListener("beforeunload", windowClose);

      console.log(`Successfully joined a Room: ${room}`);
      room.on("disconnected", roomDisconnected);
      room.on("participantConnected", participantConnected);
      room.participants.forEach(participantConnected);
      room.on("participantDisconnected", participantDisconnected);
    };

    const participantConnected = (participant: Twilio.RemoteParticipant) => {
      console.log(`Participant ${participant.identity} connected`);
      addAttendee(participant.sid);

      nextTick(async () => {
        participant.on("trackSubscribed", (track: any) => trackSubscribed(participant.sid, track));

        participant.on("trackUnsubscribed", trackUnsubscribed);

        participant.tracks.forEach((publication: Twilio.RemoteTrackPublication) => {
          if (publication.isSubscribed) trackSubscribed(participant.sid, publication.track);
        });
      });
    };

    const trackSubscribed = (id: string, track: any) => {
      document.querySelector(`#video${id}>div.container`)?.appendChild(track.attach());
      // document.getElementById(`video${id}`)?.appendChild(track.attach());

      if (track.kind == "video") setAttendeeName(id, track.name);

      track.on("disabled", () => {
        console.log(`Disable ${track.kind} track for ${id}`);
        const attende = Array.from(attendees).find((f) => f.uid === id);

        if (attende !== undefined) {
          switch (track.kind) {
            case "audio":
              attende.audioMuted = true;
              break;
            case "video":
              attende.videoMuted = true;
              break;
          }
        }
      });

      track.on("enabled", () => {
        console.log(`Enable ${track.kind} track for ${id}`);
        const attende = Array.from(attendees).find((f) => f.uid === id);

        if (attende !== undefined) {
          switch (track.kind) {
            case "audio":
              attende.audioMuted = false;
              break;
            case "video":
              attende.videoMuted = false;
              break;
          }
        }
      });
    };

    function trackUnsubscribed(track: any) {
      track.detach().forEach((element: any) => element.remove());
    }

    const participantDisconnected = (participant: Twilio.RemoteParticipant) => {
      console.log(`Participant ${participant.identity} disconnected`);
      const attende = Array.from(attendees).find((f) => f.uid === participant.sid);

      if (attende !== undefined) {
        attendees.delete(attende);
        speakingAttendees.delete(attende);
      }
    };

    const roomDisconnected = (room: Twilio.Room) => {
      console.log(`Leaving room ${room.name} [${room.sid}]`);

      room.localParticipant.tracks.forEach((publication: Twilio.LocalTrackPublication) => {
        switch (publication.kind) {
          case "audio":
            (publication.track as Twilio.LocalAudioTrack).stop();
            (publication.track as Twilio.LocalAudioTrack).detach();
            break;
          case "video":
            (publication.track as Twilio.LocalVideoTrack).stop();
            (publication.track as Twilio.LocalVideoTrack).detach();
            break;
        }

        publication.unpublish();
      });
    };

    const nameInitials = computed(() => {
      return calcInitials(configuration.persistent.userName);
    });

    const nameInitialsSvg = computed(() => {
      return calcInitialsSvg(configuration.persistent.userName);
    });

    const wvc = computed(() => {
      return `wvc${attendees.size} ${
        configuration.session.interpreterServiceActive ? "interpreterServiceActive" : ""
      }`;
    });

    const speaking = computed(() => {
      let speaking = "";

      speakingAttendees.forEach((attendee) => {
        if (attendee.name !== "") {
          const tmpName = interpreterServiceActive.value
            ? `${attendee.name} (${attendee.spokenLanguage})`
            : attendee.name;
          speaking += speaking.length > 0 ? ", " + tmpName : "Speaking: " + tmpName;
        }
      });

      return speaking;
    });

    const rootMeanSquare = (samples: Uint8Array) => {
      const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
      return Math.sqrt(sumSq / samples.length);
    };

    const pollAudioLevel = async (track: any, onLevelChanged: any) => {
      if (!audioContext) {
        return;
      }

      // Due to browsers' autoplay policy, the AudioContext is only active after
      // the user has interacted with your app, after which the Promise returned
      // here is resolved.
      await audioContext.resume();

      // Create an analyser to access the raw audio samples from the microphone.
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 1024;
      analyser.smoothingTimeConstant = 0.5;

      // Connect the LocalAudioTrack's media source to the analyser.
      const stream = new MediaStream([track.mediaStreamTrack]);
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const samples = new Uint8Array(analyser.frequencyBinCount);
      let level: number;

      // Periodically calculate the audio level from the captured samples,
      // and if changed, call the callback with the new audio level.
      requestAnimationFrame(function checkLevel() {
        analyser.getByteFrequencyData(samples);
        const rms = rootMeanSquare(samples);
        const log2Rms = rms && Math.log2(rms);

        // Audio level ranges from 0 (silence) to 10 (loudest).
        const newLevel = Math.ceil((10 * log2Rms) / 8);
        if (level !== newLevel) {
          level = newLevel;
          onLevelChanged(level);
        }

        // Continue calculating the level only if the audio track is live.
        if (track.mediaStreamTrack.readyState === "live") {
          requestAnimationFrame(checkLevel);
        } else {
          requestAnimationFrame(() => onLevelChanged(0));
        }
      });
    };

    watch(
      () => audioOn.value,
      async (newValue) => {
        if (newValue) audioTrack.enable();
        else audioTrack.disable();
      }
    );

    watch(
      () => videoOn.value,
      async (newValue) => {
        if (newValue) videoTrack.enable();
        else videoTrack.disable();
      }
    );

    watch(
      () => handUp.value,
      async (newValue) => {
        await commandSendHand(newValue);
      }
    );

    const updateChannels = async () => {
      if (!interpreterServiceActive.value) return;

      const sl = Array.from(attendees).map((f) => f.spokenLanguage);

      sl.forEach((value) => {
        if (
          configuration.persistent.spokenLanguage !== value &&
          Array.from(channels).find((f) => f.iso === value) === undefined
        ) {
          if (value != "") {
            let name: string | null;

            switch (value) {
              case "de":
                name = "Deutsch";
                break;
              case "en":
                name = "Englisch";
                break;
              case "fr":
                name = "Französisch";
                break;
              case "es":
                name = "Spanisch";
                break;
              case "zh":
                name = "Chinesisch";
                break;
              case "hi":
                name = "Hindi";
                break;
              case "ar":
                name = "Arabisch";
                break;
              case "pt":
                name = "Portugisisch";
                break;
              case "bn":
                name = "Bengalisch";
                break;
              case "ru":
                name = "Russisch";
                break;
              default:
                name = value;
                break;
            }

            channels.add({
              iso: value ?? "",
              name: name ?? "",
              enabled: value === configuration.persistent.spokenLanguage,
            });
          }
        }
      });

      // await updateAudioMute();
    };

    // const updateAudioMute = async () => {
    //   const l = Array.from(channels)
    //     .filter((f) => f.enabled)
    //     .map((f) => f.iso);

    //   participants.forEach((participant) => {
    //     if (participant.audioTrack != null) {
    //       const f =
    //         participant.spokenLanguage === configuration.persistent.spokenLanguage ||
    //         l.find((f) => f === participant.spokenLanguage) !== undefined;

    //       // if (f && !participant.audioTrack??.isPlaying) participant.audioTrack.play();
    //       // else if (!f && participant.audioTrack.isPlaying) participant.audioTrack.stop();
    //     }
    //   });
    // };

    const channelMutingChanged = async () => {
      await updateChannels();
    };

    const exit = (): void => {
      context.emit("exit");
    };

    const calcInitials = (userName: string | null) => {
      if (userName == null) return null;

      return userName
        .split(" ")
        .map((n) => n[0])
        .join("");
    };

    const calcInitialsSvg = (userName: string | null) => {
      if (userName == null) return null;

      const initials = calcInitials(userName);
      return `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="100" width="100"><circle cx="50" cy="50" r="40" fill="white" /><text x="50%" y="50%" fill="%23646464" font-size="36" font-family="Avenir, Helvetica, Arial, sans-serif" dominant-baseline="central" text-anchor="middle">${initials}</text></svg>')`;
    };

    const cleanup = (): void => {
      attendees.forEach((attendee) => {
        if (attendee.lastSeen < Date.now() - configuration.session.keepAliveInterval * 1000 * 2) {
          console.log(`Remove attendee ${attendee.uid}`);
          attendees.delete(attendee);
          speakingAttendees.delete(attendee);
          return;
        }
      });
    };

    const addAttendee = (uid: string): Attendee => {
      let attendee = Array.from(attendees).find((f) => f.uid === uid);

      attendee = {
        uid: uid,
        name: "",
        initials: calcInitials(""),
        initialsSvg: calcInitialsSvg(""),
        lastSeen: Date.now(),
        speaking: false,
        audioMuted: false,
        videoMuted: false,
        handUp: false,
        spokenLanguage: null,
        track: [],
      };

      attendees.add(attendee);

      updateChannels();

      return attendee;
    };

    const setAttendeeName = (uid: string, name: string): void => {
      let attendee = Array.from(attendees).find((f) => f.uid === uid);

      if (attendee != undefined) {
        attendee.name = name;
        attendee.initials = calcInitials(name);
        attendee.initialsSvg = calcInitialsSvg(name);
        // lastSeen: Date.now();
      }
      updateChannels();
    };

    const windowClose = async (): Promise<void> => {
      console.log("Window close");
      room.disconnect();
    };

    const commandReceiveHello = async (sender: string): Promise<void> => {
      console.log(`hello from ${sender}`);
      // await commandSendIam(rtcUid as string);
    };

    const commandReceivePing = async (sender: string): Promise<void> => {
      console.log(`ping from ${sender}`);
      let attendee = Array.from(attendees).find((f) => f.uid === sender);
      if (attendee !== undefined) attendee.lastSeen = Date.now();
    };

    // const commandReceiveIAm = async (sender: string, data: CommandIam): Promise<void> => {
    //   console.log(`I am from ${sender}, set name to ${data.name}`);
    //   addOrUpdateAttendee(
    //     sender,
    //     data.name,
    //     data.audioMuted,
    //     data.videoMuted,
    //     data.handUp,
    //     data.spokenLanguage
    //   );
    // };

    const commandReceiveHand = async (sender: string, data: CommandHand): Promise<void> => {
      console.log(`Hand from ${sender}`);
      const attendee = Array.from(attendees).find((f) => f.uid === sender);

      if (attendee !== undefined) {
        attendee.handUp = data.up;
      }
    };

    const commandReceiveBye = async (sender: string): Promise<void> => {
      console.log(`bye from ${sender}`);
      const attendee = Array.from(attendees).find((f) => f.uid === sender);

      if (attendee !== undefined) {
        attendees.delete(attendee);
        speakingAttendees.delete(attendee);
      }
    };

    const commandSendHello = async (): Promise<void> => {
      console.log("Send Hello");
      await managementApi.sendCommand(configuration.session.token, { command: "Hello" });
    };

    const commandSendPing = async (): Promise<void> => {
      console.log("Send only one ping");
      await managementApi.sendCommand(configuration.session.token, { command: "Ping" });
    };

    const commandSendIam = async (rtcUid: string): Promise<void> => {
      console.log("Send Iam");
      const data: CommandIam = {
        name: configuration.persistent.userName,
        rtcUid: rtcUid,
        videoMuted: !videoOn.value,
        audioMuted: !audioOn.value,
        handUp: handUp.value,
        spokenLanguage: configuration.persistent.spokenLanguage,
      };
      await managementApi.sendCommand(configuration.session.token, {
        command: "Iam",
        data: JSON.stringify(data),
      });
    };

    const commandSendHand = async (up: boolean): Promise<void> => {
      console.log("Send Hand");
      const data: CommandHand = {
        up: up,
      };
      await managementApi.sendCommand(configuration.session.token, {
        command: "Hand",
        data: JSON.stringify(data),
      });
    };

    const commandSendBye = async (): Promise<void> => {
      console.log("Send Bye");
      await managementApi.sendCommand(configuration.session.token, { command: "Bye" });
    };

    return {
      ...toRefs(configuration.persistent),
      ...toRefs(configuration.session),
      nameInitials,
      nameInitialsSvg,
      attendees,
      wvc,
      speaking,
      videoOn,
      audioOn,
      handUp,
      exit,
      interpreterServiceActive,
      channels,
      channelMutingChanged,
    };
  },
};
