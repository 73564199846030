import { createApp } from "vue";
import App from "./App.vue";
import { DeviceHandlerService } from "@/services/DeviceHandlerService";
import { ManagementApiService } from "@/services/ManagementApiService";
import { ConfigurationService } from "@/services/ConfigurationService";
import "./registerServiceWorker";

createApp(App)
  .provide('ConfigurationService', new ConfigurationService())
  .provide("DeviceHandlerService", new DeviceHandlerService())
  .provide("ManagementApiService", new ManagementApiService())
  .mount("#app");
