import { reactive, ref } from "vue";

export interface DeviceInfo {
  deviceId: string;
  name: string;
}

export class DeviceHandlerService {
  cams: DeviceInfo[] = reactive([]);
  mics: DeviceInfo[] = reactive([]);
  speakers: DeviceInfo[] = reactive([]);
  camId = ref("");
  micId = ref("");
  speakerId = ref("");

  constructor() {
    navigator.mediaDevices.ondevicechange = () => { this.updateDevices(); };
  }

  async isMediaSupported(): Promise<boolean> {
    try {
      const supported = "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices;
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      const hasStream = stream != null;
      this.closeCamera(stream);

      return supported && hasStream;
    } catch {
      return false;
    }
  }

  closeCamera(stream?: MediaStream): void {
    stream?.getTracks().forEach((track) => {
      track.stop();
    });
  }

  async updateDevices(): Promise<void> {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const tempCams = devices
      .filter((device) =>
        device.kind === "videoinput" &&
        device.deviceId !== "default" &&
        device.label !== ""
      )
      .map((device) => ({
        deviceId: device.deviceId,
        name: this.filterName(device.label),
      }))
      .sort((x, y) => (x.name > y.name ? 1 : -1));

    this.cams.splice(0, this.cams.length);
    this.cams.push(...tempCams);

    if (this.camId.value === "" || !this.cams.some((e) => e.deviceId === this.camId.value))
      this.camId.value = this.cams.length > 0 ? this.cams[0].deviceId : "";

    const tempMics = devices
      .filter((device) =>
        device.kind === "audioinput" &&
        device.deviceId !== "default" &&
        device.label !== ""
      )
      .map((device) => ({
        deviceId: device.deviceId,
        name: this.filterName(device.label),
      }))
      .sort((x, y) => (x.name > y.name ? 1 : -1));

    this.mics.splice(0, this.mics.length);
    this.mics.push(...tempMics);

    if (this.micId.value === "" || !this.mics.some((e) => e.deviceId === this.micId.value))
      this.micId.value = this.mics.length > 0 ? this.mics[0].deviceId : "";

    const tempSpeakers = devices
      .filter((device) =>
        device.kind === "audiooutput" &&
        device.deviceId !== "default" &&
        device.label !== ""
      )
      .map((device) => ({
        deviceId: device.deviceId,
        name: this.filterName(device.label),
      }))
      .sort((x, y) => (x.name > y.name ? 1 : -1));

    this.speakers.splice(0, this.speakers.length);
    this.speakers.push(...tempSpeakers);

    if (this.speakerId.value === "" || !this.speakers.some((e) => e.deviceId === this.speakerId.value))
      this.speakerId.value = this.speakers.length > 0 ? this.speakers[0].deviceId : "";
  }

  async openCamera(id: string): Promise<MediaStream> {
    this.camId.value = id;
    const ar = //16 / 9;
      window.matchMedia("(orientation: portrait)").matches
        ? 9 / 16
        : 16 / 9;

    const constraints = {
      video: {
        aspectRatio: ar,
        deviceId: id,
        width: { min: 640, ideal: 1280, max: 1280 },
        height: { min: 360, ideal: 720, max: 720 },
      },
    };

    return await navigator.mediaDevices.getUserMedia(constraints);
  }

  filterName(name: string): string {
    return name.replace(/\s*\((.*)\)/, "");
  }
}
