<template>
  <div class="select-wrapper">
    <select @change="select($event.target.value)">
      <option
        v-for="item in devices"
        :value="item.deviceId"
        :key="item.deviceId"
        :selected="item.deviceId === selectedId.value"
        @click="select(item.deviceId)"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { DeviceInfo } from "@/services/DeviceHandlerService";

export default {
  name: "DeviceSelection",
  props: {
    devices: {
      type: Object as () => DeviceInfo[],
      required: true,
    },
    selectedId: {
      type: Object as () => string,
      required: true,
    },
  },
  emits: ["select"],
  setup(props: any, context: any): any {
    const select = (id: string): void => {
      context.emit("select", id);
    };

    return { select };
  },
};
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  margin: 0.5rem 0 1rem 0;
}
</style>
