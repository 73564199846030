
import { onMounted, onUnmounted, ref } from "vue";
import SessionLogin from "@/components/SessionLogin.vue";
import VideoSetup from "@/components/VideoSetup.vue";
import BrowserNotSupported from "@/components/BrowserNotSupported.vue";
import Call from "@/components/Call.vue";
import { isSupported } from "twilio-video";

enum SetupStep {
  Login = 0,
  VideoSetup = 1,
  Wait = 2,
  Call = 3,
  BrowserNotSupported = 4,
}

export default {
  name: "Home",
  components: { SessionLogin, VideoSetup, Call, BrowserNotSupported },
  // eslint-disable-next-line
  setup(): any {
    const step = ref(SetupStep.Login);
    const showUpdateNotification = ref(false);
    let registration: any = null;

    onMounted(() => {
      document.addEventListener("swUpdated", updateAvailable, { once: true });
    });

    onUnmounted(() => {
      document.removeEventListener("swUpdated", updateAvailable);
    });

    const updateAvailable = (event: any): void => {
      registration = event.detail;
      showUpdateNotification.value = true;
    };

    const refreshApp = () => {
      if (registration == null || !registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    };

    const year = new Date().getFullYear() === 2021 ? "2021" : `2021 - ${new Date().getFullYear()}`;

    // Twilio support
    if (!isSupported) step.value = SetupStep.BrowserNotSupported;

    const sessionLogin = (): void => {
      step.value = SetupStep.VideoSetup;
    };

    const cancelSetup = (): void => {
      step.value = SetupStep.Login;
    };

    const okSetup = (): void => {
      step.value = SetupStep.Call;
    };

    const exitCall = (): void => {
      step.value = SetupStep.Login;
    };

    return {
      step,
      year,
      sessionLogin,
      cancelSetup,
      okSetup,
      SetupStep,
      exitCall,
      showUpdateNotification,
      refreshApp,
    };
  },
};
