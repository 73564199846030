
import { ref, nextTick, inject } from "vue";
import { ManagementApiService } from "@/services/ManagementApiService";
import { SessionInformationStatus } from "@/services/ManagementApiService";
import { ConfigurationService } from "@/services/ConfigurationService";

export default {
  name: "SessionLogin",
  emits: ["sessionLogin"],
  // eslint-disable-next-line
  setup(props: any, context: any): any {
    const managementApi = inject("ManagementApiService") as ManagementApiService;
    const configuration = inject("ConfigurationService") as ConfigurationService;

    const meetingcode = ref("");
    const meetingpassword = ref("");
    const meetingcodeerror = ref("");
    const showmeetingpassword = ref(false);
    const meetingpassworderror = ref("");
    const errortext = ref("");
    const isLoading = ref(false);

    const submit = async (): Promise<void> => {
      try {
        isLoading.value = true;
        const res = await managementApi.getSessionInformation(
          meetingcode.value,
          meetingpassword.value
        );
        isLoading.value = false;
        errortext.value = "";

        switch (res.status) {
          case SessionInformationStatus.UnknownMeetingId: {
            meetingcodeerror.value = "Unbekannt Kennnummer";
            showmeetingpassword.value = false;
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#code")?.select();
            });
            break;
          }
          case SessionInformationStatus.PasswordRequired: {
            showmeetingpassword.value = true;
            meetingpassword.value = "";
            meetingpassworderror.value = "";
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#password")?.focus();
            });
            break;
          }
          case SessionInformationStatus.PasswordWrong: {
            meetingpassworderror.value = "Falsches Passwort";
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#password")?.select();
            });
            break;
          }
          case SessionInformationStatus.OK: {
            configuration.session.sessionTitle = res.sessionName;
            configuration.session.token = res.token;
            configuration.session.interpreterServiceActive = res.interpreterActive;
            context.emit("sessionLogin");
            break;
          }
          case SessionInformationStatus.InternalServerError: {
            errortext.value = res.error;
            break;
          }
        }
      } catch (e) {
        console.error(e);
        isLoading.value = false;
        errortext.value = e.message;
      }
    };

    const codeKeydown = (): void => {
      meetingcodeerror.value = "";
      showmeetingpassword.value = false;
    };

    const passwordKeydown = (): void => {
      meetingpassworderror.value = "";
    };

    return {
      meetingcode,
      meetingpassword,
      meetingcodeerror,
      showmeetingpassword,
      meetingpassworderror,
      errortext,
      submit,
      codeKeydown,
      passwordKeydown,
      isLoading,
    };
  },
};
