<template>
  <section id="precall" v-if="step !== SetupStep.Call">
    <header>
      <!-- <img src="@/assets/logo.png" alt="Logo XYZ" /> -->
      Logo XYZ
      <div class="right">
        <a>Deutsch</a>
      </div>
    </header>
    <main>
      <div>
        <SessionLogin v-if="step === SetupStep.Login" @session-login="sessionLogin" />
        <VideoSetup v-if="step === SetupStep.VideoSetup" @cancel="cancelSetup" @ok="okSetup" />
        <BrowserNotSupported v-if="step === SetupStep.BrowserNotSupported" />
      </div>
    </main>
    <footer>
      <div class="updateNotification" v-if="showUpdateNotification">
        Es steht ein Update zur Verfügung. Bitte laden sie die Seite neu.
        <a @click="refreshApp">Neu Laden</a>
      </div>
      <small>
        © {{ year }} by XYZ. Alle Rechte vorbehalten. <a>Hilfe</a> |
        <a>Datenschutzbestimmungen</a> | <a>Geschäftbedingungen</a> |
        <a>Anmelden</a>
      </small>
    </footer>
  </section>
  <section id="call" v-if="step === SetupStep.Call">
    <Call @exit="exitCall" />
  </section>
</template>

<script lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import SessionLogin from "@/components/SessionLogin.vue";
import VideoSetup from "@/components/VideoSetup.vue";
import BrowserNotSupported from "@/components/BrowserNotSupported.vue";
import Call from "@/components/Call.vue";
import { isSupported } from "twilio-video";

enum SetupStep {
  Login = 0,
  VideoSetup = 1,
  Wait = 2,
  Call = 3,
  BrowserNotSupported = 4,
}

export default {
  name: "Home",
  components: { SessionLogin, VideoSetup, Call, BrowserNotSupported },
  // eslint-disable-next-line
  setup(): any {
    const step = ref(SetupStep.Login);
    const showUpdateNotification = ref(false);
    let registration: any = null;

    onMounted(() => {
      document.addEventListener("swUpdated", updateAvailable, { once: true });
    });

    onUnmounted(() => {
      document.removeEventListener("swUpdated", updateAvailable);
    });

    const updateAvailable = (event: any): void => {
      registration = event.detail;
      showUpdateNotification.value = true;
    };

    const refreshApp = () => {
      if (registration == null || !registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    };

    const year = new Date().getFullYear() === 2021 ? "2021" : `2021 - ${new Date().getFullYear()}`;

    // Twilio support
    if (!isSupported) step.value = SetupStep.BrowserNotSupported;

    const sessionLogin = (): void => {
      step.value = SetupStep.VideoSetup;
    };

    const cancelSetup = (): void => {
      step.value = SetupStep.Login;
    };

    const okSetup = (): void => {
      step.value = SetupStep.Call;
    };

    const exitCall = (): void => {
      step.value = SetupStep.Login;
    };

    return {
      step,
      year,
      sessionLogin,
      cancelSetup,
      okSetup,
      SetupStep,
      exitCall,
      showUpdateNotification,
      refreshApp,
    };
  },
};
</script>

<style scoped>
section {
  height: 100vh;
}

section#precall {
  padding: 0.5rem;
}

header {
  height: 50px;
}

header img {
  max-height: 100%;
  float: left;
  margin-right: 1em;
}

header div.right {
  float: right;
}

header div.right img {
  margin-right: 0.25em;
  height: 1.25em;
}

main {
  min-height: calc(100% - 115px);
}

main > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer {
  height: 65px;
  text-align: center;
}

.updateNotification {
  background-color: var(--color-warning);
  margin: 1rem;
  padding: 1em;
}

@media screen and (max-width: 960px) {
  main {
    min-height: calc(100% - 165px);
  }
}
</style>

<style src="@/assets/css/style.css"></style>
