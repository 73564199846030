<template>
  <div class="center">
    Wir benötigen den Zugriff auf ihre Video- und Audiogeräte.<br />
    Bitte geben sie den Zugriff auf ihre Geräte frei, um Teilnehmen zu können.
  </div>
</template>

<style scoped>
.center {
  text-align: center;
}
</style>
