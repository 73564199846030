<template>
  <div class="select-media">
    <h3>{{ sessionName }}</h3>
    <div class="content">
      <div class="videoplayer">
        <div class="title">So wirst du im Video aussehen:</div>
        <div class="video">
          <div class="novideo">KEIN VIDEO</div>
          <video id="localVideo" autoplay playsinline />
        </div>
        <div class="name">
          <input
            id="username"
            type="text"
            placeholder="Ihr Name"
            size="40"
            maxlength="40"
            required
            v-model.trim="username"
          />
        </div>
        <!-- https://de.wikipedia.org/wiki/Liste_der_meistgesprochenen_Sprachen -->
        <div class="spokenlanguage" v-if="interpreterServiceActive">
          <div class="select-wrapper">
            <select v-model.trim="spokenLanguage">
              <option value="">Sprache wählen</option>
              <option value="de">Deutsch</option>
              <option value="en">Englisch</option>
              <option value="fr">Französisch</option>
              <option value="es">Spanisch</option>
              <option value="zh">Chinesisch</option>
              <option value="hi">Hindi</option>
              <option value="ar">Arabisch</option>
              <option value="pt">Portugiesisch</option>
              <option value="bn">Bengalisch</option>
              <option value="ru">Russisch</option>
            </select>
          </div>
        </div>
      </div>

      <div class="settings">
        <div>
          <label class="switch">
            <input name="videoOn" id="videoOn" type="checkbox" v-model="camOn" />
            <span></span>
          </label>
          <span>Kamera:</span>
          <DeviceSelection
            :devices="deviceHandler.cams"
            :selectedId="deviceHandler.camId"
            @select="selectCam"
            v-if="deviceHandler.cams.length > 0"
          />
        </div>
        <div>
          <label class="switch">
            <input name="audioOn" id="audioOn" type="checkbox" v-model="micOn" />
            <span></span>
          </label>
          <span>Mikrofon:</span>
          <DeviceSelection
            :devices="deviceHandler.mics"
            :selectedId="deviceHandler.micId"
            @select="selectMic"
            v-if="deviceHandler.mics.length > 0"
          />

          <!-- <div v-if="deviceHandler.speakers.length > 0">
            <span>Lautsprecher:</span>
            <DeviceSelection
              :devices="deviceHandler.speakers"
              :selectedId="deviceHandler.speakerId"
              @select="selectSpeaker"
            />
          </div> -->
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="buttons">
        <button @click="cancelSetup" :disabled="isLoading">Abbrechen</button>
        <button class="primary" @click="ok" :disabled="isLoading">Jetzt Teilnehmen</button>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="errortext" class="error">
        {{ errortext }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { nextTick, onMounted, inject, ref } from "vue";
import { DeviceHandlerService } from "@/services/DeviceHandlerService";
import DeviceSelection from "@/components/DeviceSelection.vue";
import { ConfigurationService } from "@/services/ConfigurationService";
import { ManagementApiService } from "@/services/ManagementApiService";

export default {
  name: "DevicePreview",
  emits: ["cancel", "ok"],
  components: { DeviceSelection },
  // eslint-disable-next-line
  setup(props: any, context: any): any {
    const deviceHandler = inject("DeviceHandlerService") as DeviceHandlerService;
    const configuration = inject("ConfigurationService") as ConfigurationService;
    const managementApi = inject("ManagementApiService") as ManagementApiService;
    let mediaStream: MediaStream;
    const username = ref("");
    const sessionName = ref("");
    const camOn = ref(true);
    const micOn = ref(true);
    const errortext = ref("");
    const isLoading = ref(false);
    const interpreterServiceActive = configuration.session.interpreterServiceActive;
    const spokenLanguage = ref(configuration.persistent.spokenLanguage);

    onMounted(
      async (): Promise<void> => {
        deviceHandler.camId.value = configuration.persistent.camera;
        deviceHandler.micId.value = configuration.persistent.microphone;
        deviceHandler.speakerId.value = configuration.persistent.speaker;
        username.value = configuration.persistent.userName;
        sessionName.value = configuration.session.sessionTitle;

        await deviceHandler.updateDevices();

        nextTick(() => {
          showVideo(deviceHandler.camId.value);
        });
      }
    );

    const showVideo = async (id: string): Promise<void> => {
      const videoElement = document.querySelector<HTMLVideoElement>("#localVideo");

      if (videoElement != null) {
        deviceHandler.camId.value = id;
        mediaStream = await deviceHandler.openCamera(id);
        videoElement.srcObject = mediaStream;
      }
    };

    const cancelSetup = (): void => {
      deviceHandler.closeCamera(mediaStream);
      context.emit("cancel");
    };

    const selectCam = (id: string): void => {
      const stream = mediaStream;
      showVideo(id);
      // console.log(stream.getVideoTracks()[0].getCapabilities());
      // console.log(stream.getVideoTracks()[0].getConstraints());
      // console.log(stream.getVideoTracks()[0].getSettings());
      deviceHandler.closeCamera(stream);
    };

    const selectMic = (id: string): void => {
      deviceHandler.micId.value = id;
    };

    const selectSpeaker = (id: string): void => {
      deviceHandler.speakerId.value = id;
    };

    const ok = async (): Promise<void> => {
      errortext.value = "";
      configuration.persistent.camera = deviceHandler.camId.value;
      configuration.persistent.microphone = deviceHandler.micId.value;
      configuration.persistent.speaker = deviceHandler.speakerId.value;
      configuration.persistent.userName = username.value;
      configuration.persistent.spokenLanguage = spokenLanguage.value;
      configuration.session.camOn = camOn.value;
      configuration.session.micOn = micOn.value;

      try {
        isLoading.value = true;
        const res = await managementApi.callLogin(configuration.session.token);
        configuration.session.userId = res.uid;
        configuration.session.token = res.token;
        configuration.session.room = res.room;
        configuration.session.codec = res.codec;
        configuration.session.tokenRenewInterval = res.tokenRenewInterval;
        configuration.session.keepAliveInterval = res.keepAliveInterval;
        isLoading.value = false;
        configuration.save();
        deviceHandler.closeCamera(mediaStream);
        context.emit("ok");
      } catch (e) {
        console.error(e);
        isLoading.value = false;
        errortext.value = e.message;
      }
    };

    return {
      deviceHandler,
      cancelSetup,
      selectCam,
      selectMic,
      selectSpeaker,
      username,
      sessionName,
      camOn,
      micOn,
      ok,
      errortext,
      isLoading,
      interpreterServiceActive,
      spokenLanguage,
    };
  },
};
</script>

<style scoped>
.select-media {
  margin-bottom: 2rem;
}

.select-media > div.content {
  display: flex;
  flex-flow: row nowrap;
  /* align-content: stretch; */
  border: 1px solid var(--color-medium);
  border-radius: var(--border-radius-input);
  padding: 1em;
}

.select-media h3 {
  text-align: center;
}

.videoplayer {
  padding: 4rem;
  width: 50vw;
  text-align: center;
  color: var(--color-dark);

  border-radius: var(--border-radius-input);
  background: rgb(61, 70, 87);
  background: linear-gradient(220deg, rgba(61, 70, 87, 1) 0%, rgba(35, 41, 52, 1) 100%);
  display: grid;
  grid-template-rows: 4rem auto 4rem;
}

.videoplayer .title {
  font-size: 1.2rem;
  font-weight: bold;
}

.videoplayer .video {
  align-self: center;
  position: relative;
}

.videoplayer .video .novideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videoplayer .video video {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 30vh;
  border-radius: var(--border-radius-input);
  pointer-events: none;
  z-index: 1;
}

.videoplayer .name {
  align-self: end;
}

.videoplayer .name input {
  width: 100%;
}

.settings {
  width: 14rem;
  margin-left: 1em;
}

.settings > div {
  clear: both;
}

.select-media .buttons {
  margin-top: 1em;
  text-align: right;
}

.select-media .buttons button {
  margin: 0 0 1em 1em;
}

.switch + span {
  line-height: 34px;
}

.error {
  text-align: center;
}

@media screen and (max-width: 960px) {
  .select-media {
    width: 100%;
  }

  .select-media h3 {
    margin: 0;
  }

  .select-media > div.content {
    flex-flow: column nowrap;
    border: none;
    padding: 0;
  }

  .videoplayer {
    width: 100%;
    padding: 0;
    color: var(--color-light);
    background: none;
    display: block;
  }

  .videoplayer .title {
    font-size: 1rem;
    font-weight: normal;
  }

.videoplayer .video  {
    margin: 1rem 0;
  }

  .videoplayer .video video {
    max-height: 100%;
  }

  .settings {
    width: 100%;
    margin: 1em 0 0 0;
  }
}
</style>
