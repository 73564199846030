<template #default>
  <transition appear name="fade">
    <div class="login" :class="{ loading: isLoading }">
      <h1>Beitreten</h1>
      <form @submit.prevent="submit" autocomplete="off">
        <input
          @keydown="codeKeydown"
          v-model.trim="meetingcode"
          id="code"
          type="text"
          placeholder="Kennummer"
          size="20"
          maxlength="20"
          required
          :disabled="isLoading"
        />
        <transition name="fade">
          <div class="error" v-if="meetingcodeerror">
            {{ meetingcodeerror }}
          </div>
        </transition>
      </form>
      <transition name="fade">
        <form v-if="showmeetingpassword" @submit.prevent="submit" autocomplete="off">
          <input
            @keydown="passwordKeydown"
            v-model.trim="meetingpassword"
            id="password"
            type="password"
            placeholder="Passwort"
            size="20"
            maxlength="20"
            required
            :disabled="isLoading"
          />
          <transition name="fade">
            <div class="error" v-if="meetingpassworderror">
              {{ meetingpassworderror }}
            </div>
          </transition>
        </form>
      </transition>
      <transition name="fade">
        <div v-if="errortext" class="error">
          {{ errortext }}
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts">
import { ref, nextTick, inject } from "vue";
import { ManagementApiService } from "@/services/ManagementApiService";
import { SessionInformationStatus } from "@/services/ManagementApiService";
import { ConfigurationService } from "@/services/ConfigurationService";

export default {
  name: "SessionLogin",
  emits: ["sessionLogin"],
  // eslint-disable-next-line
  setup(props: any, context: any): any {
    const managementApi = inject("ManagementApiService") as ManagementApiService;
    const configuration = inject("ConfigurationService") as ConfigurationService;

    const meetingcode = ref("");
    const meetingpassword = ref("");
    const meetingcodeerror = ref("");
    const showmeetingpassword = ref(false);
    const meetingpassworderror = ref("");
    const errortext = ref("");
    const isLoading = ref(false);

    const submit = async (): Promise<void> => {
      try {
        isLoading.value = true;
        const res = await managementApi.getSessionInformation(
          meetingcode.value,
          meetingpassword.value
        );
        isLoading.value = false;
        errortext.value = "";

        switch (res.status) {
          case SessionInformationStatus.UnknownMeetingId: {
            meetingcodeerror.value = "Unbekannt Kennnummer";
            showmeetingpassword.value = false;
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#code")?.select();
            });
            break;
          }
          case SessionInformationStatus.PasswordRequired: {
            showmeetingpassword.value = true;
            meetingpassword.value = "";
            meetingpassworderror.value = "";
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#password")?.focus();
            });
            break;
          }
          case SessionInformationStatus.PasswordWrong: {
            meetingpassworderror.value = "Falsches Passwort";
            nextTick(() => {
              document.querySelector<HTMLInputElement>("#password")?.select();
            });
            break;
          }
          case SessionInformationStatus.OK: {
            configuration.session.sessionTitle = res.sessionName;
            configuration.session.token = res.token;
            configuration.session.interpreterServiceActive = res.interpreterActive;
            context.emit("sessionLogin");
            break;
          }
          case SessionInformationStatus.InternalServerError: {
            errortext.value = res.error;
            break;
          }
        }
      } catch (e) {
        console.error(e);
        isLoading.value = false;
        errortext.value = e.message;
      }
    };

    const codeKeydown = (): void => {
      meetingcodeerror.value = "";
      showmeetingpassword.value = false;
    };

    const passwordKeydown = (): void => {
      meetingpassworderror.value = "";
    };

    return {
      meetingcode,
      meetingpassword,
      meetingcodeerror,
      showmeetingpassword,
      meetingpassworderror,
      errortext,
      submit,
      codeKeydown,
      passwordKeydown,
      isLoading,
    };
  },
};
</script>

<style scoped>
/* .login {
  padding: 1rem 4rem 2rem 4rem;
  border-radius: var(--border-radius-input);
  transition:  0.5s ease;
}

.login.loading {
  background-color: #eee;
  transition:  0.5s ease;
} */

h1,
form {
  text-align: center;
  margin: 3rem 0 0 0;
}
.error {
  margin-top: 1.5em;
}
</style>
