import { reactive } from "vue";

export class ConfigurationService {
  constructor() {
    this.load();
  }

  persistent = reactive({
    camera: "",
    microphone: "",
    speaker: "",
    userName: "",
    spokenLanguage: "",
  });

  session = reactive({
    sessionTitle: "",
    interpreterServiceActive: false,
    camOn: false,
    micOn: false,
    token: "",
    apiToken: "",
    room: "",
    appId: "",
    userId: "",
    codec: "" as "h264" | "vp8",
    encryptionMode: "",
    encryptionPassword: "",
    channelConnection: "",
    tokenRenewInterval: 0,
    keepAliveInterval: 0,
  });

  save(): void {
    this.setPersistentValue("Camera", this.persistent.camera);
    this.setPersistentValue("Microphone", this.persistent.microphone);
    // this.setPersistentValue("Speaker", this.persistent.speaker);
    this.setPersistentValue("UserName", this.persistent.userName);
    this.setPersistentValue("SpokenLanguage", this.persistent.spokenLanguage);
  }

  load(): void {
    let value = this.getPersistentValue("Camera");

    if (value) {
      this.persistent.camera = value;
    }

    value = this.getPersistentValue("Microphone");

    if (value) {
      this.persistent.microphone = value;
    }

    value = this.getPersistentValue("Speaker");

    if (value) {
      this.persistent.speaker = value;
    }

    value = this.getPersistentValue("UserName");

    if (value) {
      this.persistent.userName = value;
    }

    value = this.getPersistentValue("SpokenLanguage");

    if (value) {
      this.persistent.spokenLanguage = value;
    }
  }

  setPersistentValue(name: string, value: string): void {
    localStorage.setItem(name, value);
    // const expires = 60*60*24*365;
    // document.cookie = `${name}=${value}; max-age=${expires}; path=/;`;
  }

  getPersistentValue(name: string): string | null {
    // const b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    // return b ? b.pop() : '';
    return localStorage.getItem(name);
  }
}
