<template>
  <div class="center">
    BROWSER NOT SUPPORTED
  </div>
</template>

<style scoped>
.center {
  text-align: center;
}
</style>
